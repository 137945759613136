import React from 'react'
import styled from 'styled-components'
import { stripUnit, mix, rgba } from 'polished'

import { animationCurve, animationTimes, borderRadius } from 'styles/variables'
import { white, black } from 'styles/colors'
import { fontSizes } from 'styles/typography'
import * as spacing from 'styles/spacing'
import { largerThan } from 'styles/media'

import Arrow from 'assets/icons/arrow-top-right.svg'

import Heading from 'components/Heading'
import Icon from 'components/Icon'

const Container = styled.a`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  min-height: 300px;
  position: relative;
  border-radius: ${spacing.scale(borderRadius, 1.25)};
  background-color: ${(props) => mix(0.15, props.color, white)};
  transition: background-color ${animationTimes.base} ${animationCurve};

  &:hover {
    background-color: ${(props) => mix(0.2, props.color, white)};
  }

  ${largerThan.small`
    min-height: 360px;
  `};

  ${largerThan.medium`
    min-height: 400px;
  `};

  ${largerThan.large`
    min-height: 440px;
  `};

  ${largerThan.xLarge`
    min-height: 480px;
  `};
`

const Inner = styled.div`
  position: relative;
  z-index: 10;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${spacing.large};

  ${largerThan.large`
    padding: ${spacing.xLarge};
  `};

  ${largerThan.xLarge`
    padding: ${spacing.xxLarge};
  `};
`

const Header = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${spacing.large};

  ${largerThan.small`
    height: 48px;
  `};

  ${largerThan.medium`
    height: 56px;
  `};

  ${largerThan.large`
    height: 80px;
  `};

  ${largerThan.xLarge`
    height: 96px;
  `};
`

const Logo = styled.div`
  display: flex;
  width: ${(props) => Math.round(stripUnit(props.logoWidth) * 0.7) + 'px'};

  ${largerThan.small`
    width: ${(props) => Math.round(stripUnit(props.logoWidth) * 0.8) + 'px'};
  `};

  ${largerThan.large`
    width: ${(props) => Math.round(stripUnit(props.logoWidth) * 0.9) + 'px'};
  `};

  ${largerThan.xLarge`
    width: ${(props) => Math.round(stripUnit(props.logoWidth) * 1) + 'px'};
  `};

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`

const Main = styled.div`
  margin-bottom: ${spacing.medium};

  ${largerThan.medium`
    margin-bottom: ${spacing.large};
  `};

  ${largerThan.xLarge`
    margin-bottom: ${spacing.xLarge};
  `};
`

const Title = styled(Heading)`
  color: ${black};
  margin-bottom: ${spacing.small};

  ${largerThan.large`
    margin-bottom: ${spacing.scale(spacing.small, 1.5)};
  `};

  ${largerThan.xLarge`
    margin-bottom: ${spacing.medium};
  `};
`

const Description = styled.p`
  color: ${(props) => rgba(mix(0.3, props.color, black), 0.8)};
  margin-bottom: 0;
`

const IconWrap = styled.div`
  display: flex;
  opacity: 0;
  visibility: hidden;
  color: ${(props) => props.color};
  will-change: opacity, visibility, transform;
  transform: translateX(-${spacing.tiny}) translateY(${spacing.tiny});
  transition: all ${animationTimes.base} ${animationCurve};

  ${Container}:hover & {
    transform: none;
    opacity: 1;
    visibility: visible;
  }
`

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75%;
  background-image: linear-gradient(
    0deg,
    ${(props) => rgba(props.color, 0)} 0%,
    ${(props) => rgba(props.color, 0.15)} 100%
  );
  border-radius: ${spacing.scale(borderRadius, 1.25)}
    ${spacing.scale(borderRadius, 1.25)} 0 0;
`

const ClientCard = ({ color, logo, logoWidth, heading, description, link }) => (
  <Container
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    color={color}
  >
    <Gradient color={color} />
    <Inner>
      <Header>
        <Logo logoWidth={logoWidth}>{logo}</Logo>
        <IconWrap color={color}>
          <Icon icon={<Arrow />} />
        </IconWrap>
      </Header>
      <Content>
        <Main>
          <Title size={4}>{heading}</Title>
          <Description color={color}>{description}</Description>
        </Main>
      </Content>
    </Inner>
  </Container>
)

export default ClientCard

import React from 'react'
import styled from 'styled-components'

import { INFO_FOR_PROJECT_NAME } from 'utils/constants'

import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import CasePreview from 'components/CasePreview'
import { Grid, GridRow, GridColumn } from 'components/Grid'
import OtherWork from 'components/work/OtherWork'

const clients = ['aldo', 'usewalter', 'vape']

const Divider = styled.div`
  background-color: ${({ theme }) => theme.border};
  height: 1px;
`

const Cases = () => (
  <Section flushTop>
    <Wrapper>
      <SectionBlock>
        <Grid>
          <GridRow>
            {clients.map((client, i) => {
              const handle = INFO_FOR_PROJECT_NAME[client]

              return (
                <GridColumn xs={12} md={6}>
                  <CasePreview
                    key={i}
                    color={handle.color}
                    logo={handle.logo}
                    logoWidth={handle.logoWidth}
                    heading={handle.heading}
                    figure={handle.figure}
                    description={handle.description}
                    slug={handle.slug}
                    isDisabled={handle.comingSoon}
                    year={handle.year}
                    tag={handle.comingSoon ? 'Coming soon' : null}
                  />
                </GridColumn>
              )
            })}
          </GridRow>
        </Grid>
      </SectionBlock>
      <SectionBlock>
        <Divider />
      </SectionBlock>
      <SectionBlock>
        <OtherWork />
      </SectionBlock>
    </Wrapper>
  </Section>
)

export default Cases

import React from 'react'

import MetaTags from 'components/MetaTags'
import PageHeader from 'components/PageHeader'
import Cases from 'components/work/Cases'

const Work = () => (
  <>
    <MetaTags title="Work | Custom software development in Montreal | Volume7" />
    <PageHeader
      heading={
        <>
          Selected <span>work</span>
        </>
      }
      description="We've done work for organizations of varying size, from growth-stage startups to large enterprises, across a wide diversity of industries."
    />
    <Cases />
    {/* <OtherWork /> */}
  </>
)

export default Work

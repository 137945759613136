import React from 'react'

import { INFO_FOR_PROJECT_NAME } from 'utils/constants'

import { SectionBlock } from 'components/Section'
import ClientCard from 'components/ClientCard'
import { Grid, GridRow, GridColumn } from 'components/Grid'
import ClientTestimonials from 'components/misc/ClientTestimonials'

const clients = [
  'arc',
  'peach',
  'affirm',
  'desjardins',
  'qbio',
  'truework',
  'consensys',
  'beamery',
  'bell',
  'plenty',
  'sentilink',
  'cushion',
  'creditkarma',
  'yellow-pages',
]

const OtherWork = () => (
  <>
    <SectionBlock>
      <Grid>
        <GridRow>
          {clients.map((client, i) => {
            const handle = INFO_FOR_PROJECT_NAME[client]

            return (
              <GridColumn xs={12} sm={6} md={4} key={i}>
                <ClientCard
                  color={handle.color}
                  logo={handle.logo}
                  logoWidth={handle.logoWidth}
                  heading={handle.heading}
                  description={handle.description}
                  link={handle.link}
                />
              </GridColumn>
            )
          })}
        </GridRow>
      </Grid>
    </SectionBlock>
    <SectionBlock>
      <ClientTestimonials />
    </SectionBlock>
  </>
)

export default OtherWork
